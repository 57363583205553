export default {
  namespaced: true,
  state: {
    credentials:
      (JSON.parse(
        localStorage.getItem("credentials") as string
      ) as CredentialsInterface) || {},
    hasAccessToken: !!localStorage.getItem("credentials") as boolean,
  },

  mutations: {
    login(state: any, credentials: CredentialsInterface) {
      state.hasAccessToken = true;
      state.credentials = credentials;
    },
    logout(state: any) {
      state.credentials = {};
      state.hasAccessToken = false;
    },
  },

  actions: {
    login({ commit }: any, payload: CredentialsInterface) {
      commit("login", payload);
      localStorage.setItem("credentials", JSON.stringify(payload));
    },
    logout({ commit }: any) {
      commit("logout");
      localStorage.removeItem("credentials");
      localStorage.removeItem("lang");
    },
  },

  getters: {
    hasAccessToken: (state: any): boolean => state.hasAccessToken,
    credentials: (state: any): CredentialsInterface => state.credentials,
  },
};
