import Vue from "vue";
import VueI18n from "vue-i18n";
import store from "@/store";

Vue.use(VueI18n);

function loadLocaleMessages() {
  const locales = require.context(
    "../modules",
    true,
    /[A-Za-z0-9-_,\s]+\.json$/i
  );
  const messages = {
    en: {},
    ru: {},
    ro: {},
  };
  locales.keys().forEach((key) => {
    const [, lang] = key.match(/([A-Za-z0-9-_]+)\./i);
    messages[lang] = { ...messages[lang], ...locales(key) };
  });

  messages.ru = {
    ...messages.ru,
    ...require("../locales/ru.json"),
  };

  messages.ro = {
    ...messages.ro,
    ...require("../locales/ro.json"),
  };

  messages.en = {
    ...messages.en,
    ...require("../locales/en.json"),
  };

  return messages;
}

export default new VueI18n({
  locale: store.getters["localization/getCurrent"],
  fallbackLocale: store.getters["localization/getCurrent"],
  messages: loadLocaleMessages(),
});
