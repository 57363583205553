const meta = {
  module: "about",
};

export default [
  {
    path: `/about`,
    name: "about",
    component: () =>
      import(/* webpackChunkName: "home" */ "../../modules/about/views/index"),
    meta: {
      ...meta,
    },
    // },
    // {
    //   path: `/confirm`,
    //   name: "confirm",
    //   component: () =>
    //     import(
    //       /* webpackChunkName: "confirm" */ "../../modules/response/views/confirm"
    //     ),
    //   meta: {
    //     module: "confirm",
    //   },
  },
];
