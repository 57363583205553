import Vue from "vue";
import Vuex from "vuex";
import localization from "./modules/localization";
import alert from "./modules/alert";
import authentication from "@/store/modules/authentication.ts";
import user from "@/store/modules/user.ts";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    localization,
    authentication,
    user,
    alert,
  },
});
