import store from "@/store";

export default {
  namespaced: true,

  state: {
    currentLang: localStorage.getItem("locale") || "ro",
    allowedLanguages: ["en", "ru", "ro"],
  },

  mutations: {
    set(state, lang) {
      state.currentLang = lang;
      localStorage.setItem("locale", lang);
    },
  },

  actions: {
    set(context, lang) {
      context.commit("set", lang);
    },
    setFromRouter(context) {
      const [, , , urlLang] = location.href.split("/");
      console.log(urlLang);
      const allowedLang = store.getters["localization/getAllowed"];

      if (allowedLang.includes(urlLang)) {
        context.commit("set", urlLang);
      }
    },
  },

  getters: {
    getCurrent: (state) => state.currentLang,
    getAllowed: (state) => state.allowedLanguages,
  },
};
